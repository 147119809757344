import { CCol } from "@coreui/react";
import { Language } from "../../language/English";
import CIcon from "@coreui/icons-react"
import { cilCash, cilEnvelopeLetter, cilGroup, cilMobile, cilUser } from "@coreui/icons";
import { BOOKING_PAYMENT_STATUS_CREATED, BOOKING_PAYMENT_STATUS_FAILED, BOOKING_PAYMENT_STATUS_NOT_NEEDED, BOOKING_PAYMENT_STATUS_SUCCESS, PAYMENT_CURRENCY_MULTIPLIER } from "../../services/Booking/BookingConstant";
import { BookingPaymentPojo } from "../../services/Booking/BookingPojo/BookingPaymentPojo";
import { useState } from "react";
import Modal from "../../components/Layout/Modal";
import IndividualPaymentBookingDetails from "./IndividualPaymentBookingDetails";
import { BookingPaymentBookingDetailsPojo } from "../../services/Booking/BookingPojo/BookingPaymentBookingDetailsPojo";
import PaymentBookingDetails from "./PaymentBookingDetails";
import { format } from "date-fns";

const SingleBookingPayment = (props: { bookingPayments: BookingPaymentPojo, key: number, count: number }) => {
    const bookingPayment = props.bookingPayments;
    const businessDetails = bookingPayment.business;
    const guestDetails = bookingPayment.guestDetails;
    const bookingDetails = bookingPayment.bookingDetails ?? null;
    const items = bookingDetails.items ?? null;
    const bookingServices = Array.isArray(items)
        ? items.map(([key, details]) => ({
            key,
            ...details
        }))
        : [];
    const paymentRequestDateTime = new Date(bookingPayment.paymentRequestedOn * 1000); // Convert seconds to milliseconds
    const paymentFinishDateTime = bookingPayment.paymentFinishedOn && new Date(bookingPayment.paymentFinishedOn * 1000); // Convert seconds to milliseconds
    const [visible, setVisible] = useState(false);
    const [getCreatePaymentIntentRawRequest, setCreatePaymentIntentRawRequest] = useState(false);
    const [getCreatePaymentIntentRawResponse, setCreatePaymentIntentRawResponse] = useState(false);
    const [getCapturePaymentRawRequest, setCapturePaymentRawRequest] = useState(false);
    const [getCapturePaymentRawResponse, setCapturePaymentRawResponse] = useState(false);
    const [getUpdatePaymentIntentRawRequest, setUpdatePaymentIntentRawRequest] = useState(false);
    const [getUpdatePaymentIntentRawResponse, setUpdatePaymentIntentRawResponse] = useState(false);
    const [getRetrievePaymentIntentDetailsRequest, setRetrievePaymentIntentDetailsRequest] = useState(false);
    const [getRetrievePaymentIntentDetailsResponse, setRetrievePaymentIntentDetailsResponse] = useState(false);
    const [getClientPaymentFailedError, setClientPaymentFailedError] = useState(false);
    const [getServerPaymentFailedError, setServerPaymentFailedError] = useState(false);

    return (
        <>
            <CCol md={4} className="mt-2">
                <div className={`card`} id={`imageId-1`}>
                    <div className="topDateTimeSection">
                        {
                            paymentRequestDateTime && (
                                <>
                                    <div className="requestOnSection">
                                        <p className="label">
                                            {Language.REQUEST_ON}
                                        </p>
                                        <p className="date">
                                            {format(paymentRequestDateTime, "dd MMM yyyy, hh:mm a")}
                                        </p>
                                    </div>
                                </>
                            )
                        }
                        {
                            paymentFinishDateTime && (
                                <>
                                    <div className="finishOnSection">
                                        <p className="label">
                                            {Language.FINISH_ON}
                                        </p>
                                        <p className="date">
                                            {format(paymentFinishDateTime, "dd MMM yyyy, hh:mm a")}
                                        </p>
                                    </div>
                                </>
                            )
                        }
                    </div>

                    <div className={`card-body images`}>
                        <CCol md={9} className="card-images col-9">
                            <p className="text-info">
                                <b>
                                    <CIcon icon={cilCash} /> &nbsp;
                                    {bookingPayment.paymentCurrency}
                                    &nbsp;
                                    {bookingPayment.amount / PAYMENT_CURRENCY_MULTIPLIER}
                                </b>
                            </p>
                        </CCol>
                        <CCol md={3} className="card-images col-3">
                            {
                                bookingPayment.paymentStatus === BOOKING_PAYMENT_STATUS_CREATED ? (<strong className="text-warning">{Language.PROCESSING}</strong>)
                                    : bookingPayment.paymentStatus === BOOKING_PAYMENT_STATUS_SUCCESS ? (<strong className="text-success">{Language.SUCCEED}</strong>)
                                        : bookingPayment.paymentStatus === BOOKING_PAYMENT_STATUS_FAILED ? (<strong className="text-danger">{Language.FAILED}</strong>)
                                            : bookingPayment.paymentStatus === BOOKING_PAYMENT_STATUS_NOT_NEEDED ? (<strong className="text-info">{Language.NOT_NEEDED}</strong>)
                                                : ''
                            }
                        </CCol>
                    </div>
                    <CCol md={12} className="card-images col-12 business-detail-box">
                        <p>
                            <b><a href={businessDetails.businessUrl} target="_blank" rel="noreferrer">{businessDetails.name}</a></b>
                            <br></br>
                            <a href={`tel:${businessDetails.phoneCountryCode}${businessDetails.phoneNumber}`}>{`+${businessDetails.phoneCountryCode}${businessDetails.phoneNumber}`}</a>
                            <br></br>
                            {businessDetails.addressLine1}, {businessDetails.addressLine2}, {businessDetails.addressLine3}
                        </p>
                        {
                            guestDetails && (
                                <CCol md={12} className="card-images col-12">
                                    <hr />
                                    <p className="text-center"><b>{Language.GUEST_DETAILS}</b></p>
                                    <div className="guestDetails">
                                        {guestDetails.guestName && (<p><CIcon icon={cilUser} /> {guestDetails.guestName}</p>)}
                                        {guestDetails.mobileNumber && guestDetails.mobileNumber.length > 5 && (<p><CIcon icon={cilMobile} /> {`${guestDetails.mobileNumber}`}</p>)}
                                        {guestDetails.email && (<p><CIcon icon={cilEnvelopeLetter} /> {guestDetails.email}</p>)}
                                        {guestDetails.numberOfGuest && (<p><CIcon icon={cilGroup} /> {guestDetails.numberOfGuest} {Language.GUEST}</p>)}
                                    </div>

                                </CCol>
                            )
                        }

                        <CCol md={12} className="card-images col-12">
                            <hr />
                            <div className="modal-links">
                                {items && (
                                    <>
                                        <p className="bookedServices">
                                            <span onClick={() => setVisible(!visible)}>
                                                {Language.VIEW_BOOKING_DETAIL}
                                            </span>
                                        </p>
                                        <Modal
                                            title={businessDetails.name}
                                            visible={visible}
                                            className="booking-modal"
                                        >
                                            <PaymentBookingDetails
                                                bookingAmount={bookingDetails.totalAmount}
                                                bookingDetails={bookingDetails}
                                            >
                                                {bookingServices?.length > 0 &&
                                                    bookingServices.map(
                                                        (service: BookingPaymentBookingDetailsPojo, index: number) => (
                                                            <IndividualPaymentBookingDetails
                                                                key={index}
                                                                bookingService={service}
                                                                index={index}
                                                            />
                                                        )
                                                    )}
                                            </PaymentBookingDetails>
                                        </Modal>
                                    </>
                                )}

                                {bookingPayment.createPaymentIntentRawRequest && (
                                    <>
                                        <p className="bookedServices">
                                            <span onClick={() => setCreatePaymentIntentRawRequest(!getCreatePaymentIntentRawRequest)}>
                                                {Language.CREATE_PAYMENT_INTENT_RAW_REQUEST}
                                            </span>
                                        </p>
                                        <Modal
                                            title={businessDetails.name}
                                            visible={getCreatePaymentIntentRawRequest}
                                            className="booking-modal object-show"
                                        >
                                            <div className="modal-body">
                                                <pre>{JSON.stringify(bookingPayment.createPaymentIntentRawRequest, null, 2)}</pre>
                                            </div>
                                        </Modal>
                                    </>
                                )}

                                {bookingPayment.createPaymentIntentRawResponse && (
                                    <>
                                        <p className="bookedServices">
                                            <span onClick={() => setCreatePaymentIntentRawResponse(!getCreatePaymentIntentRawResponse)}>
                                                {Language.CREATE_PAYMENT_INTENT_RAW_RESPONSE}
                                            </span>
                                        </p>
                                        <Modal
                                            title={businessDetails.name}
                                            visible={getCreatePaymentIntentRawResponse}
                                            className="booking-modal object-show"
                                        >
                                            <div className="modal-body">
                                                <pre>{JSON.stringify(bookingPayment.createPaymentIntentRawResponse, null, 2)}</pre>
                                            </div>
                                        </Modal>
                                    </>
                                )}

                                {bookingPayment.updatePaymentIntentRawRequest && (
                                    <>
                                        <p className="bookedServices">
                                            <span onClick={() => setUpdatePaymentIntentRawRequest(!getUpdatePaymentIntentRawRequest)}>
                                                {Language.UPDATE_PAYMENT_INTENT_RAW_REQUEST}
                                            </span>
                                        </p>
                                        <Modal
                                            title={businessDetails.name}
                                            visible={getUpdatePaymentIntentRawRequest}
                                            className="booking-modal object-show"
                                        >
                                            <div className="modal-body">
                                                <pre>{JSON.stringify(bookingPayment.updatePaymentIntentRawRequest, null, 2)}</pre>
                                            </div>
                                        </Modal>
                                    </>
                                )}

                                {bookingPayment.updatePaymentIntentRawResponse && (
                                    <>
                                        <p className="bookedServices">
                                            <span onClick={() => setUpdatePaymentIntentRawResponse(!getUpdatePaymentIntentRawResponse)}>
                                                {Language.UPDATE_PAYMENT_INTENT_RAW_RESPONSE}
                                            </span>
                                        </p>
                                        <Modal
                                            title={businessDetails.name}
                                            visible={getUpdatePaymentIntentRawResponse}
                                            className="booking-modal object-show"
                                        >
                                            <div className="modal-body">
                                                <pre>{JSON.stringify(bookingPayment.updatePaymentIntentRawResponse, null, 2)}</pre>
                                            </div>
                                        </Modal>
                                    </>
                                )}

                                {bookingPayment.capturePaymentRawRequest && (
                                    <>
                                        <p className="bookedServices">
                                            <span onClick={() => setCapturePaymentRawRequest(!getCapturePaymentRawRequest)}>
                                                {Language.CAPTURE_PAYMENT_RAW_REQUEST}
                                            </span>
                                        </p>
                                        <Modal
                                            title={businessDetails.name}
                                            visible={getCapturePaymentRawRequest}
                                            className="booking-modal object-show"
                                        >
                                            <div className="modal-body">
                                                <pre>{JSON.stringify(bookingPayment.capturePaymentRawRequest, null, 2)}</pre>
                                            </div>
                                        </Modal>
                                    </>
                                )}

                                {bookingPayment.capturePaymentRawResponse && (
                                    <>
                                        <p className="bookedServices">
                                            <span onClick={() => setCapturePaymentRawResponse(!getCapturePaymentRawResponse)}>
                                                {Language.CAPTURE_PAYMENT_RAW_RESPONSE}
                                            </span>
                                        </p>
                                        <Modal
                                            title={businessDetails.name}
                                            visible={getCapturePaymentRawResponse}
                                            className="booking-modal object-show"
                                        >
                                            <div className="modal-body">
                                                <pre>{JSON.stringify(bookingPayment.capturePaymentRawResponse, null, 2)}</pre>
                                            </div>
                                        </Modal>
                                    </>
                                )}

                                {bookingPayment.retrievePaymentIntentDetailsRequest && (
                                    <>
                                        <p className="bookedServices">
                                            <span onClick={() => setRetrievePaymentIntentDetailsRequest(!getRetrievePaymentIntentDetailsRequest)}>
                                                {Language.RETRIEVE_PAYMENT_INTENT_DETAILS_REQUEST}
                                            </span>
                                        </p>
                                        <Modal
                                            title={businessDetails.name}
                                            visible={getRetrievePaymentIntentDetailsRequest}
                                            className="booking-modal object-show"
                                        >
                                            <div className="modal-body">
                                                <pre>{JSON.stringify(bookingPayment.retrievePaymentIntentDetailsRequest, null, 2)}</pre>
                                            </div>
                                        </Modal>
                                    </>
                                )}

                                {bookingPayment.retrievePaymentIntentDetailsResponse && (
                                    <>
                                        <p className="bookedServices">
                                            <span onClick={() => setRetrievePaymentIntentDetailsResponse(!getRetrievePaymentIntentDetailsResponse)}>
                                                {Language.RETRIEVE_PAYMENT_INTENT_DETAILS_RESPONSE}
                                            </span>
                                        </p>
                                        <Modal
                                            title={businessDetails.name}
                                            visible={getRetrievePaymentIntentDetailsResponse}
                                            className="booking-modal object-show"
                                        >
                                            <div className="modal-body">
                                                <pre>{JSON.stringify(bookingPayment.retrievePaymentIntentDetailsResponse, null, 2)}</pre>
                                            </div>
                                        </Modal>
                                    </>
                                )}

                                {bookingPayment.clientPaymentFailedError && (
                                    <>
                                        <p className="bookedServices">
                                            <span onClick={() => setClientPaymentFailedError(!getClientPaymentFailedError)}>
                                                {Language.CLIENT_PAYMENT_FAILED_ERROR}
                                            </span>
                                        </p>
                                        <Modal
                                            title={businessDetails.name}
                                            visible={getClientPaymentFailedError}
                                            className="booking-modal object-show"
                                        >
                                            <div className="modal-body">
                                                <pre>{JSON.stringify(bookingPayment.clientPaymentFailedError, null, 2)}</pre>
                                            </div>
                                        </Modal>
                                    </>
                                )}

                                {bookingPayment.serverPaymentFailedError && (
                                    <>
                                        <p className="bookedServices">
                                            <span onClick={() => setServerPaymentFailedError(!getServerPaymentFailedError)}>
                                                {Language.SERVER_PAYMENT_FAILED_ERROR}
                                            </span>
                                        </p>
                                        <Modal
                                            title={businessDetails.name}
                                            visible={getServerPaymentFailedError}
                                            className="booking-modal object-show"
                                        >
                                            <div className="modal-body">
                                                <pre>{JSON.stringify(bookingPayment.serverPaymentFailedError, null, 2)}</pre>
                                            </div>
                                        </Modal>
                                    </>
                                )}

                            </div>
                        </CCol>
                    </CCol>
                </div>
            </CCol>
        </>
    )
}
export default SingleBookingPayment;