import CIcon from "@coreui/icons-react"
import { cilListRich, cilPlus, cilSearch, cilIndustrySlash, cilDescription, cilBook, cilChatBubble, cilListLowPriority, cilLocationPin, cilCash } from "@coreui/icons"
import { CNavItem } from "@coreui/react"
import { INavigation } from "../../types"
import { PAGE_MANAGE_IMAGE, PAGE_ADD_BUSINESS, PAGE_BUSINESS_TYPE, PAGE_LIST_BUSINESS, PAGE_BOOKINGS, PAGE_ARTICLES, PAGE_FEEDBACKS, PAGE_SEARCH_META_DATA, PAGE_SERVICE, PAGE_REGION, PAGE_NEW_BUSINESS_SIGN_UP_REQUEST, PAGE_BOOKING_PAYMENT } from "../../PagesNames"
import { Language } from "../../language/English"
import { USER_TYPE_ADMIN, USER_TYPE_SALES } from "../../Constant"

const commonPermissions = [
  Language.ADD_BUSINESS,
  Language.LIST_BUSINESS,
  Language.WELLNESS_SIGN_UP_CENTER,
  Language.BOOKINGS,
]; 

export const NavigationPermissions: any = {
  [USER_TYPE_ADMIN]: [
    ...commonPermissions,
    Language.FEEDBACKS,
    Language.SEARCH_META_DATA,
    Language.SERVICE,
    Language.REGION,
    Language.BUSINESS_TYPES,
    Language.ARTICLES,
    Language.MANAGE_IMAGES,
    Language.BOOKING_PAYMENTS,
  ],
  [USER_TYPE_SALES]: [
    ...commonPermissions
  ]
};


export const Navigation: INavigation[] = [
  {
    component: CNavItem,
    name: Language.ADD_BUSINESS,
    to: PAGE_ADD_BUSINESS,
    icon: <CIcon icon={cilPlus} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: Language.LIST_BUSINESS,
    to: PAGE_LIST_BUSINESS,
    icon: <CIcon icon={cilListRich} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: Language.WELLNESS_SIGN_UP_CENTER,
    to: PAGE_NEW_BUSINESS_SIGN_UP_REQUEST,
    icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: Language.BOOKINGS,
    to: PAGE_BOOKINGS,
    icon: <CIcon icon={cilBook} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: Language.BOOKING_PAYMENTS,
    to: PAGE_BOOKING_PAYMENT,
    icon: <CIcon icon={cilCash} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: Language.FEEDBACKS,
    to: PAGE_FEEDBACKS,
    icon: <CIcon icon={cilChatBubble} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: Language.SEARCH_META_DATA,
    to: PAGE_SEARCH_META_DATA,
    icon: <CIcon icon={cilSearch} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: Language.SERVICE,
    to: PAGE_SERVICE,
    icon: <CIcon icon={cilListLowPriority} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: Language.REGION,
    to: PAGE_REGION,
    icon: <CIcon icon={cilLocationPin} customClassName="nav-icon" />,
  },
  {
		component: CNavItem,
		name: Language.BUSINESS_TYPES,
		to: PAGE_BUSINESS_TYPE,
		icon: <CIcon icon={cilIndustrySlash} customClassName="nav-icon" />,
	  },
  {
    component: CNavItem,
    name: Language.ARTICLES,
    to: PAGE_ARTICLES,
    icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: Language.MANAGE_IMAGES,
    to: PAGE_MANAGE_IMAGE,
    icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
  }
];