import { BASE_URL } from "../../Constant";

export const API_GET_ALL_BOOKINGS: string = `${BASE_URL}/booking/getAll`;
export const API_BOOKING_CHANGE_STATUS: string = `${BASE_URL}/booking/changeStatus`;
export const API_BOOKING_PAYMENTS: string = `${BASE_URL}/booking/getBookingPayments`;

export const BOOKING_STATUS_RECEIVED: number = 1;
export const BOOKING_STATUS_ACCEPTED: number = 2;
export const BOOKING_STATUS_REJECTED: number = 3;
export const BOOKING_STATUS_DELETED: number = 0;
export const PAYMENT_CURRENCY_MULTIPLIER: number = 100;

export const BOOKING_PAYMENT_STATUS_CREATED: number = 1;
export const BOOKING_PAYMENT_STATUS_SUCCESS: number = 2;
export const BOOKING_PAYMENT_STATUS_FAILED: number = 3;
export const BOOKING_PAYMENT_STATUS_NOT_NEEDED: number = 4;