import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from "@coreui/react";
import { Language } from "../../../language/English";
const DeleteVideoModal = (props: {showDeleteVideoModal : boolean, setShowDeleteVideoModal : Function, confirmDelete : Function}) => {
    return(
        <CModal alignment="center" visible={props.showDeleteVideoModal} onClose={() => props.setShowDeleteVideoModal(false)}>
            <CModalHeader>
                <CModalTitle>{Language.DELETE_VIDEO}</CModalTitle>
            </CModalHeader>
            <CModalBody> {Language.ARE_YOU_SURE_TO_DELETE_THIS_VIDEO} </CModalBody>
            <CModalFooter>
                <CButton color="secondary" onClick={() => props.setShowDeleteVideoModal(false)}> {Language.NO} </CButton>
                <CButton color="primary" onClick={() => props.confirmDelete()}> {Language.YES} </CButton>
            </CModalFooter>
        </CModal>
    )
}
export default DeleteVideoModal;