import { Language } from "../../language/English";
import { PAYMENT_CURRENCY_MULTIPLIER } from "../../services/Booking/BookingConstant";

const PaymentBookingDetails = (props: any) => {
    const bookingDetails = props.bookingDetails;

    return (
        <>
            <div className="modal-body">
                <div className="price-main-wrapper price-main-wrapper-show">
                    <div className="price-container">
                        {
                            bookingDetails.bookingDate && bookingDetails.bookingTime && (
                                <div className="timing-location-flex-box">
                                    <span className="time-tag-btn">
                                        {bookingDetails.bookingDate} {Language.AT} {bookingDetails.bookingTime}
                                    </span>
                                </div>
                            )
                        }
                        <ul className="price-tag-list">

                            {
                                props.children
                            }

                        </ul>
                    </div>
                    <div className="price-tag-wrapper">
                        <div className="total-amount">
                            <span className="price-total-tag">{Language.BOOKING_AMOUNT}</span>
                            <span className="price-total-value">{Language.AED} {props.bookingAmount/PAYMENT_CURRENCY_MULTIPLIER}</span>
                        </div>
                        {
                            bookingDetails.payNowAmount && (
                                <div className="pay-now-amount">
                                    <span className="price-total-tag">{Language.PAID}</span>
                                    <span className="price-total-value">{Language.AED} {bookingDetails.payNowAmount/PAYMENT_CURRENCY_MULTIPLIER}</span>
                                </div>
                            )
                        }
                        {
                            bookingDetails.payInShopAmount && (
                                <div className="pay-shop-amount">
                                    <span className="price-total-tag">{Language.PAY_IN_SHOP}</span>
                                    <span className="price-total-value">{Language.AED} {bookingDetails.payInShopAmount/PAYMENT_CURRENCY_MULTIPLIER}</span>
                                </div>
                            )
                        }
                    </div>
                    
                </div>
            </div>
        </>
    )
}

export default PaymentBookingDetails;