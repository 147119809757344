export const PAGE_DASHBOARD: string = '/dashboard'
export const PAGE_LOGIN: string = '/'
export const PAGE_BLOG_CREATE: string = '/blog/create'
export const PAGE_BLOG_LIST: string = '/blog/list'
export const PAGE_BLOG_DETAIL: string = '/blog/detail'
export const PAGE_BLOG_UPDATE: string = '/blog/update'
export const PAGE_BLOG_CREATE_TRANSLATION: string = '/blog/createTranslation'
export const PAGE_CONTACT_LIST: string = '/contact/list'
export const PAGE_ADD_BUSINESS: string = '/business/add'
export const PAGE_BUSINESS: string = '/business'
export const PAGE_LIST_BUSINESS: string = '/business/list'
export const PAGE_EDIT_BUSINESS: string = '/business/edit'
export const PAGE_BUSINESS_MEDIA: string = '/business/media'
export const PAGE_BUSINESS_TIMINGS: string = '/business/timings'
export const PAGE_BUSINESS_EMPLOYEES: string = '/business/employees'
export const PAGE_NOTES: string = '/business/notes'
export const PAGE_BOOKINGS: string = '/bookings'
export const PAGE_BOOKING_PAYMENT: string = '/booking/payments'
export const PAGE_FEEDBACKS: string = '/feedbacks'
export const PAGE_SEARCH_META_DATA: string = '/search-meta-data'
export const PAGE_SERVICE: string = '/service'
export const PAGE_REGION: string = '/region'
export const PAGE_BUSINESS_SEARCH_DETAILS: string  = 'business-search-details'
export const PAGE_BUSINESS_NOTIFICATION: string  = 'notification'
export const PAGE_ARTICLES: string = '/articles'
export const PAGE_SINGLE_ARTICLE: string = '/article'
export const PAGE_NEW_BUSINESS_SIGN_UP_REQUEST: string  = '/wellness-center-sign-up'
export const PAGE_BUSINESS_TYPE: string  = '/business-type'
export const PAGE_MANAGE_IMAGE: string  = '/manage-images'

