import { BASE_URL } from "../../../Constant";

export const API_UPLOAD_BUSINESS_VIDEOS : string = BASE_URL + '/business/video';           
export const API_LOAD_BUSINESS_ID_VIDEOS : string = BASE_URL + '/business/video/loadByBusinessId';            
export const API_DELETE_BUSINESS_VIDEO : string = BASE_URL + '/business/video';  
export const API_CHANGE_VIDEO_TAG : string = BASE_URL + '/business/video/changeVideoTag';  

export const VIDEO_TYPE_BUSINESS_VIDEOS = 1;


export const VIDEO_TAG_PRIMARY = 1;
export const VIDEO_TAG_INNER = 2;
export const VIDEO_TAG_OUTSIDE = 3;
export const VIDEO_TAG_NAVIGATION = 4;

export const VIDEO_FORMATS_ALLOWED : string = 'video/mp4,video/quicktime,video/x-matroska,video/webm,video/x-msvideo,video/ogg,video/3gpp';