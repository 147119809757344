import { Language } from "../../language/English";
import { PAYMENT_CURRENCY_MULTIPLIER } from "../../services/Booking/BookingConstant";
import { BookingPaymentBookingDetailsPojo } from "../../services/Booking/BookingPojo/BookingPaymentBookingDetailsPojo";

const IndividualPaymentBookingDetails = (props: { bookingService: BookingPaymentBookingDetailsPojo, key: number, index: number }) => {
    const { bookingService, index } = props;
    const getDuration = (duration: string): string => {
        let content = '';
    
        try {
            // Validate the input duration
            if (!duration || typeof duration !== 'string') {
                throw new Error('Invalid duration: duration must be a non-empty string.');
            }
    
            // Ensure proper format by validating if duration is in HH:mm:ss or similar ISO format
            const isoDuration = `1970-01-01T${duration}Z`;
            const parsedDate = new Date(isoDuration);
    
            if (isNaN(parsedDate.getTime())) {
                throw new Error('Invalid duration format.');
            }
    
            // Extract hours, minutes, and seconds
            const hours = parsedDate.getUTCHours() ? `${parsedDate.getUTCHours()} Hrs` : '';
            const minutes = parsedDate.getUTCMinutes() ? `${parsedDate.getUTCMinutes()} Min` : '';
            const seconds = parsedDate.getUTCSeconds() ? `${parsedDate.getUTCSeconds()} Sec` : '';
    
            // Combine the result
            content = `${hours} ${minutes} ${seconds}`.trim();
        } catch (error) {
            console.error('Error in getDuration:', (error as Error).message);
        }
    
        return content;
    };
    
    const formattedDuration = getDuration(bookingService.duration);
    return (
        <>
            <li className="price-list-wrapper" key={index}>
                <div className="flex-service-detail">
                    <span className="category-service-count">{bookingService.count}x</span>
                    <div className="category-flex-name-box">
                        <h5 className="category-service-name"> {bookingService.title} </h5>
                        <span className="category-service-time">
                            {formattedDuration}
                        </span>
                    </div>
                </div>
                <div className="category-flexbox">
                    <span className="category-price-tag">
                    {Language.AED} {bookingService.count * (bookingService.price / PAYMENT_CURRENCY_MULTIPLIER)}
                    </span>
                </div>
            </li>
        </>
    );
};

export default IndividualPaymentBookingDetails;
