import { CCol } from "@coreui/react";
import { useEffect, useMemo, useRef, useState } from "react";
import { cilDelete } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import PreviewVideoPojo from "../../services/Business/Video/VideoPojo/PreviewVideoPojo";
import VideoTagDropdown from "./VideoTagDropdown";
import VideoPojo from "../../services/Business/Video/VideoPojo/VideoPojo";
import videoWaitImage from './../../assets/images/video-wait-image.png'
import VideoService from "../../services/Business/Video/VideoService";
import { Language } from "../../language/English";

const SingleVideo = (props : { video : PreviewVideoPojo|VideoPojo, changeVideoTagHandler : Function, deleteVideoHandler : Function, isDatabaseVideo : boolean }) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const videoService = useMemo(() => new VideoService(), []);

    const video = props.video;
    const [ showWaitMessage, setShowWaitMessage ] = useState<boolean>(false);
    const changeVideoTagHandler = (videoTag : number) => {
        props.changeVideoTagHandler(video, videoTag, props.isDatabaseVideo);
    }
    useEffect(() => {
        if(props.isDatabaseVideo && videoService.isVideoPojo(video) ){
            const createdOn = video.createdOn;
            const tenMinutesAgo = Math.floor(Date.now() / 1000) - 600;
            if(createdOn >= tenMinutesAgo){
                setShowWaitMessage(true);
            }
        }
    }, [props.isDatabaseVideo, video, videoService]);
    return (
        <CCol md={3} key={video.videoId} className={`mt-2 video-container `}>
            <div className={`card ${ video.highlightVideo ? 'card-highlight' : ''}`}  id={`videoId-`+video.videoId}>
            {
                showWaitMessage && <img src={videoWaitImage} alt={Language.VIDEO_UPLOADED_SUCCESSFULLY} className="card-img-top" />
            }
            {
                !showWaitMessage && <video ref={videoRef} controls poster={video.thumbnail}> <source src={video.videoUrl} /> </video>
            }

            <div className={`card-body`}>
                <span className="delete_image" onClick={() => props.deleteVideoHandler(video, props.isDatabaseVideo)}>
                    <CIcon icon={cilDelete} />
                </span> 
                <VideoTagDropdown dropdownValue={video.videoTag} changeVideoTagHandler={changeVideoTagHandler}/>
            </div>
            </div>
        </CCol>
    )
}
export default SingleVideo;