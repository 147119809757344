import { Layout } from "../../../components/Layout";
import BusinessNav from "../BusinessNav";
import PaymentForm from "./PaymentForm";

const Payment = () => {
    return(
        <Layout>
            <BusinessNav />
            <PaymentForm />
        </Layout>
    )
}
export default Payment;