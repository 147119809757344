import { Layout } from "../../components/Layout";
import { useEffect, useState } from "react";
import { BookingService } from "../../services/Booking/BookingService";
import { Language } from "../../language/English";
import Loader from "../../components/Layout/Loader";
import Notification from "../../services/Notification/Notification";
import Logger from "../../services/Logger/Logger";
import SingleBookingPayment from "./SingleBookingPayment";
import { CCol, CRow } from "@coreui/react";
import { BookingPaymentPojo } from "../../services/Booking/BookingPojo/BookingPaymentPojo";

const BookingPayment = () => {
    const [bookingPayments, setBookingPayments] = useState<BookingPaymentPojo[]>([]);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    
    useEffect(() => {
        const notification = new Notification();
        const logger = new Logger();
        const fetchBookingPayments = async() => {
            try{
                const bookingService = new BookingService();
                const response = await bookingService.getBookingPayments();
                if(response){
                    setBookingPayments(response.bookingPayments);
                } else {
                    notification.error(Language.CANNOT_LOAD_BUSINESSES_TECHNICAL_ERROR);
                }
                setShowLoader(false);
            } catch (e){
                notification.error(Language.SOMETHING_IS_WRONG);
                setShowLoader(false);
                logger.error(e);
            }
        }
        setShowLoader(true);
        fetchBookingPayments();
    }, []);
    return (
        <>
            { showLoader ? <Loader /> : null }
            <Layout>
                <div className="list bookingList">           
                    <CCol md={12}>
                        <CRow>
                            {
                                bookingPayments.length > 0 && bookingPayments.map( (bookingPayments, index) => (
                                    <SingleBookingPayment bookingPayments={bookingPayments} key={bookingPayments.bookingPaymentId} count={index}/>
                                ))
                            }
                        </CRow>
                    </CCol>
                </div>
            </Layout>
        </>
        
    )
}
export default BookingPayment;