import { CFormSelect } from "@coreui/react";
import { Language } from "../../language/English";
import { VIDEO_TAG_INNER, VIDEO_TAG_NAVIGATION, VIDEO_TAG_OUTSIDE, VIDEO_TAG_PRIMARY } from "../../services/Business/Video/VideoConstants";
import { useEffect, useState } from "react";

const VideoTagDropdown = (props : { dropdownValue : number|null, changeVideoTagHandler : Function }) => {
    const [ dropdownValue, setDropdownValue ] = useState<number>(0);
    useEffect(() => {
        if(props.dropdownValue === null){
            setDropdownValue(0);
        } else {
            setDropdownValue(props.dropdownValue);
        }
    }, [props.dropdownValue]);
    return (
        <CFormSelect label={Language.VIDEO_TAG}  value={dropdownValue} onChange={(e) => props.changeVideoTagHandler(e.target.value)}>
            <option value={0} key={0}>{Language.SELECT_VIDEO_TAG}</option>
            <option value={VIDEO_TAG_PRIMARY} key={VIDEO_TAG_PRIMARY}>{Language.IMAGE_TYPE_PRIMARY}</option>
            <option value={VIDEO_TAG_INNER} key={VIDEO_TAG_INNER}>{Language.INNER}</option>
            <option value={VIDEO_TAG_OUTSIDE} key={VIDEO_TAG_OUTSIDE}>{Language.OUTSIDE}</option>
            <option value={VIDEO_TAG_NAVIGATION} key={VIDEO_TAG_NAVIGATION}>{Language.NAVIGATION}</option>
        </CFormSelect>
    )
}

export default VideoTagDropdown;