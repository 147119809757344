import { CContainer, CRow } from "@coreui/react";
import PreviewVideoPojo from "../../../services/Business/Video/VideoPojo/PreviewVideoPojo";
import SingleVideo from "../../../components/Video/SingleVideo";
import VideoPojo from "../../../services/Business/Video/VideoPojo/VideoPojo";
import VideoService from "../../../services/Business/Video/VideoService";
import { useMemo, useState } from "react";
import DeleteVideoModal from "./DeleteVideoModal";
import ChangeVideoTagModal from "./ChangeVideoTagModal";
import Notification from "../../../services/Notification/Notification";
import { Language } from "../../../language/English";
import { VIDEO_TAG_PRIMARY } from "../../../services/Business/Video/VideoConstants";

const VideoList = (props : { previewVideos : PreviewVideoPojo[], setPreviewVideos : Function, databaseVideos : VideoPojo[], setDatabaseVideos : Function, setShowVideoSaveWarning : Function, resetFileInput : Function }) => {
    const [video, setVideo] = useState<PreviewVideoPojo|VideoPojo>();
    const [showDeleteVideoModal, setShowDeleteVideoModal] = useState<boolean>(false);
    const [showChangeVideoTagModal, setShowChangeVideoTagModal] = useState<boolean>(false);
    const [videoTag, setVideoTag] = useState<number>();
    const notification = useMemo(() => new Notification(), []) ;
    const videoService = useMemo(() => new VideoService(), []);

    const confirmChangeVideoTag = async() => {
        if(video !== undefined && videoTag !== undefined && videoService.isVideoPojo(video)){
            const response = await videoService.changeVideoTag(video.videoId, videoTag, video.businessId);
            if(response){
                let updatedVideos = props.databaseVideos.map(databaseVideo => {
                    if (databaseVideo.videoId === video.videoId) {
                        return { ...video, videoTag: videoTag, highlightVideo : false };
                    }
                    return databaseVideo;
                });
                props.setDatabaseVideos(updatedVideos);
                notification.success(Language.SUCCESSFULLY_UPDATE_VIDEO_TAG);
            }
            setShowChangeVideoTagModal(false);
            setVideo(undefined);
            setVideoTag(undefined);
        }
    }
    const resetHighlightedVideo = () => {
        if(props.databaseVideos !== undefined){
            const updatedDatabaseVideos = props.databaseVideos.map(databaseVideo => ({ ...databaseVideo, highlightVideo: false }));
            props.setDatabaseVideos(updatedDatabaseVideos);
        }
        if(props.previewVideos !== undefined){
            const updatedPreviewVideos = props.previewVideos.map(previewVideo => ({ ...previewVideo, highlightVideo: false }));
            props.setPreviewVideos(updatedPreviewVideos);
        }
    }

    const handlePrimaryVideoSelection = (databaseVideos: VideoPojo[], previewVideos: PreviewVideoPojo[], setDatabaseVideos: Function, setPreviewVideos: Function, video: PreviewVideoPojo | VideoPojo) => {
        const allVideos = [...databaseVideos, ...previewVideos];
        const primaryVideo = allVideos.filter(v => Number(v.videoTag) === VIDEO_TAG_PRIMARY);
        if (primaryVideo.length > 0) {
            const updatedDatabaseVideos = databaseVideos.map(databaseVideo => {
                if (databaseVideo.videoTag === VIDEO_TAG_PRIMARY && databaseVideo.videoId !== video.videoId) {
                    return { ...databaseVideo, highlightVideo: true };
                }
                return databaseVideo;
            });
            const updatedPreviewVideos = previewVideos.map(previewVideo => {
                if (previewVideo.videoTag === VIDEO_TAG_PRIMARY && previewVideo.videoId !== video.videoId) {
                    return { ...previewVideo, highlightVideo: true };
                }
                return previewVideo;
            });
            setDatabaseVideos(updatedDatabaseVideos);
            setPreviewVideos(updatedPreviewVideos);
            notification.error(Language.PRIMARY_VIDEO_CANNOT_BE_MORE_THAN_ONE);
            return false;
        }
        return true;
    };

    const changeVideoTagHandler = (video : PreviewVideoPojo|VideoPojo, videoTag : number, isDatabaseVideo : boolean) => {
        if(video !== undefined){
            resetHighlightedVideo();
            const numericVideoTag = Number(videoTag);
            if (numericVideoTag === VIDEO_TAG_PRIMARY) {
                const response = handlePrimaryVideoSelection( props.databaseVideos, props.previewVideos, props.setDatabaseVideos, props.setPreviewVideos, video );
                if(!response) return;
            }
            if(!isDatabaseVideo && props.previewVideos !== undefined){
                const updatedVideos = props.previewVideos.map((previewVideo) => {
                    if (previewVideo.videoId === video.videoId) {
                        return { ...video, videoTag: numericVideoTag, highlightVideo : false };
                    } 
                    return { ...previewVideo,  highlightVideo : false };
                });
                props.setPreviewVideos(updatedVideos);
            } else {
                setVideoTag(numericVideoTag);
                setShowChangeVideoTagModal(true);
                setVideo(video);
            }
        }
    }

    const confirmDelete = async() => {
        if(video !== undefined){
            const response = await videoService.delete(video.videoId);
            if(response){
                let updatedVideos =  props.databaseVideos.filter(databaseVideo => databaseVideo.videoId !== video.videoId);
                props.setDatabaseVideos(updatedVideos);
                notification.success(Language.SUCCESSFULLY_DELETED_VIDEO);
            }
            setShowDeleteVideoModal(false);
            setVideo(undefined);
        }
    }

    const deleteVideoHandler = async (video : PreviewVideoPojo|VideoPojo, isDatabaseVideo : boolean) => {
        if(video !== undefined){
            if(isDatabaseVideo === false){
                let updatedVideos =  props.previewVideos.filter(previewVideo => previewVideo.videoId !== video.videoId);
                if(updatedVideos.length === 0 || updatedVideos === undefined) props.setShowVideoSaveWarning(false);
                props.setPreviewVideos(updatedVideos);
                props.resetFileInput();
            } else {
                setShowDeleteVideoModal(true);
                setVideo(video);
            }
        }
    }

    return (
        <CContainer>
            <CRow>
                {
                    props.previewVideos !== undefined && props.previewVideos.map((video : PreviewVideoPojo) => (
                        <SingleVideo video={video} key={video.videoId} changeVideoTagHandler={changeVideoTagHandler} deleteVideoHandler={deleteVideoHandler} isDatabaseVideo={false} />
                    ))
                } {
                    props.databaseVideos !== undefined && props.databaseVideos.map((video : VideoPojo) => (
                        <SingleVideo video={video} key={video.videoId} changeVideoTagHandler={changeVideoTagHandler} deleteVideoHandler={deleteVideoHandler} isDatabaseVideo={true}/>
                    ))
                }
                <DeleteVideoModal showDeleteVideoModal={showDeleteVideoModal} setShowDeleteVideoModal={setShowDeleteVideoModal} confirmDelete={confirmDelete}/>
                <ChangeVideoTagModal showChangeVideoTagModal={showChangeVideoTagModal} setShowChangeVideoTagModal={setShowChangeVideoTagModal} confirmChangeVideoTag={confirmChangeVideoTag}/>
            </CRow>
        </CContainer>
    )
}
export default VideoList;