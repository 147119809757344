import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from "@coreui/react";
import { Language } from "../../../language/English";
const ChangeVideoTagModal = (props: {showChangeVideoTagModal : boolean, setShowChangeVideoTagModal : Function, confirmChangeVideoTag : Function}) => {
    return(
        <CModal alignment="center" visible={props.showChangeVideoTagModal} onClose={() => props.setShowChangeVideoTagModal(false)}>
            <CModalHeader>
                <CModalTitle>{Language.CHANGE_VIDEO_TAG}</CModalTitle>
            </CModalHeader>
            <CModalBody> {Language.ARE_YOU_SURE_TO_CHANGE_VIDEO_TAG} </CModalBody>
            <CModalFooter>
                <CButton color="secondary" onClick={() => props.setShowChangeVideoTagModal(false)}> {Language.NO} </CButton>
                <CButton color="primary" onClick={() => props.confirmChangeVideoTag()}> {Language.YES} </CButton>
            </CModalFooter>
        </CModal>
    )
}
export default ChangeVideoTagModal;