import HttpService from "../../Http/HttpService";
import Logger from "../../Logger/Logger";
import Validator from "../../Validator/Validator";
import { API_CHANGE_VIDEO_TAG, API_DELETE_BUSINESS_VIDEO, API_LOAD_BUSINESS_ID_VIDEOS, API_UPLOAD_BUSINESS_VIDEOS } from "./VideoConstants";
import LoadVideosPojo from "./VideoPojo/LoadVideosPojo";
import PreviewVideoPojo from "./VideoPojo/PreviewVideoPojo";
import SaveBusinessVideoPojo from "./VideoPojo/SaveBusinessVideoPojo";
import VideoPojo from "./VideoPojo/VideoPojo";
import VideoSaveResponsePojo from "./VideoPojo/VideoSaveResponsePojo";

export default class VideoService {
    private httpService = new HttpService();
    private logger = new Logger();
    private validator = new Validator();

    async saveBusinessVideo(data : SaveBusinessVideoPojo) : Promise<VideoSaveResponsePojo>{
        try{
            const formData = new FormData();
            formData.append('businessId', String(data.businessId));
            data.videos.forEach((video: PreviewVideoPojo, index: number) => {
                formData.append(`videos[${index}][file]`, video.file);
                formData.append(`videos[${index}][videoTag]`, String(video.videoTag));
            });
            const httpResult = await this.httpService.postFormData(API_UPLOAD_BUSINESS_VIDEOS, formData);
            return { isSuccess : httpResult.data.isSuccess, videos : httpResult.data.response.videos };
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }

    async loadByBusinessId(businessId: number) : Promise<LoadVideosPojo>{
        try{
            this.validator.isNumberGreaterThan('businessId', businessId);
            const url: string = API_LOAD_BUSINESS_ID_VIDEOS + '/' + businessId;
            const httpResult =  await this.httpService.get(url, {});
            return { isSuccess : httpResult.data.isSuccess, videos : httpResult.data.response.videos }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }

    async delete(videoId: number) : Promise<boolean>{
        try {
            this.validator.isNumberGreaterThan('videoId', videoId);
            const url: string = API_DELETE_BUSINESS_VIDEO;
            const httpResult = await this.httpService.delete(url, {videoId});
            return httpResult.data.response;
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }

    async changeVideoTag(videoId: number, videoTag : number, businessId : number) : Promise<boolean>{
        try {
            this.validator.isNumberGreaterThan('videoId', videoId);
            this.validator.isNumberGreaterThan('videoTag', videoTag);
            this.validator.isNumberGreaterThan('businessId', businessId);
            const url: string = API_CHANGE_VIDEO_TAG;
            const httpResult = await this.httpService.put(url, {videoId, videoTag, businessId });
            return httpResult.data.response;
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }

    isVideoPojo(video: PreviewVideoPojo | VideoPojo): video is VideoPojo {
        return (video as VideoPojo).createdOn !== undefined;
    }
}