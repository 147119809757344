import { API_ADD_BUSINESS, API_LOAD_BY_ID_BUSINESS,API_UPDATE_BUSINESS_NOTIFICATION, API_UPDATE_BUSINESS, API_FETCH_ALL_BUSINESSES, API_SAVE_REVIEW_DETAILS, API_GET_REVIEW_DETAILS, BUSINESS_DOCUMENT_TYPE_AGREEMENT_DOC, API_UPDATE_BUSINESS_STATUS, API_IS_URL_EXIST, API_UPDATE_IS_AGREEMENT_DONE, API_BUSINESS_LOAD_SERVICES, API_BUSINESS_LICENSE_DOCUMENT, API_BUSINESS_AGREEMENT_DOCUMENT, API_SAVE_BUSINESS_SEARCH_DETAILS, API_UPDATE_BUSINESS_PAYMENT } from "./BusinessConstant";
import HttpService from "../Http/HttpService";
import { BusinessPojo } from "./BusinessPojo/BusinessPojo";
import Logger from "../Logger/Logger";
import Validator from "../Validator/Validator";
import  BusinessUpdateStatusResultPojo from "./BusinessPojo/BusinessUpdateStatusResultPojo";
import  BusinessLoadByIdPojo from "./BusinessPojo/BusinessLoadByIdResultPojo";
import  BusinessSavePojo from "./BusinessPojo/BusinessSaveResultPojo";
import  BusinessFetchAllPojo from "./BusinessPojo/BusinessFetchAllResultPojo";
import BusinessUrlExistResultPojo from "./BusinessPojo/BusinessUrlExistResultPojo";
import BusinessUpdateAgreementPojo from "./BusinessPojo/BusinessUpdateAgreementResultPojo";
import OverallReviewPojo from "./BusinessPojo/OverallReviewPojo";
import OverallReviewLoadByBusinessPojo from "./BusinessPojo/OverallReviewLoadByBusinessPojo";
import BusinessLoadServicesResultPojo from "./BusinessPojo/BusinessLoadServicesResultPojo";
import BusinessLicenseDocumentFetchResultPojo from "./BusinessPojo/BusinessLicenseDocumentFetchResultPojo";
export class BusinessService {
    private httpService = new HttpService()
    private logger = new Logger();
    private validator = new Validator();
    async create(data: BusinessPojo) : Promise<BusinessSavePojo>{
        try{
            const httpResult = await this.httpService.post(API_ADD_BUSINESS, data);
            return { isSuccess : httpResult.data.isSuccess, businessId : httpResult.data.response.businessId, message : httpResult.data.response };
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async update(data: BusinessPojo) : Promise<BusinessSavePojo>{
        try{
            const httpResult = await this.httpService.put(API_UPDATE_BUSINESS, data);
            return { isSuccess : httpResult.data.isSuccess, businessId : httpResult.data.response.businessId, message : httpResult.data.response.message };
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async fetchAll(business: number, pageNumber: number, filterBy: number|null) : Promise<BusinessFetchAllPojo>{
        try{
            const url: string = API_FETCH_ALL_BUSINESSES + '?business='+ business +'&page=' + pageNumber + '&filterBy=' + filterBy;
            const httpResult = await this.httpService.get(url, {});
            return { isSuccess : httpResult.data.isSuccess, businesses : httpResult.data.response.businesses, totalResults : httpResult.data.response.totalResults };
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async loadById(businessId: number): Promise<BusinessLoadByIdPojo>{
        try{
            this.validator.isNumberGreaterThan('businessId', businessId, 0);
            const url = `${API_LOAD_BY_ID_BUSINESS}${businessId}`;
            const httpResult = await this.httpService.get(url, {});
            return { isSuccess : httpResult.data.isSuccess, business : httpResult.data.response.business } ;
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }

    async updateStatus(businessId : number, status: number) : Promise<BusinessUpdateStatusResultPojo> {
        try{
            this.validator.isNumberGreaterThan('businessId', businessId, 0);
            this.validator.isNumberGreaterThan('status', status, 0);
            const data = { businessId, status }
            const httpResult = await this.httpService.put(API_UPDATE_BUSINESS_STATUS, data);
            return { isSuccess : httpResult.data.isSuccess, message : httpResult.data.response.message };
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async isUrlExist(url: string) : Promise<BusinessUrlExistResultPojo> {
        try{
            this.validator.isValidString('url', url);
            const api = `${API_IS_URL_EXIST}${url}`;
            const httpResult = await this.httpService.get(api, {});
            return { isSuccess : httpResult.data.isSuccess, businessId : httpResult.data.response.businessId };;
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async updateIsAgreementDone(data: BusinessUpdateAgreementPojo) : Promise<boolean> {
        try{
            const httpResult = await this.httpService.put(API_UPDATE_IS_AGREEMENT_DONE, data);
            return httpResult.data.isSuccess;
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async getReviewDetails(businessId: number) : Promise<OverallReviewLoadByBusinessPojo>{
        try {
            this.validator.isNumberGreaterThan('businessId', businessId);
            const url = `${API_GET_REVIEW_DETAILS}${businessId}`;
            const httpResult = await this.httpService.get(url, {});
            return { isSuccess : httpResult.data.isSuccess, overallReview : httpResult.data.response.overallReview }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async saveReviewDetails(data: OverallReviewPojo) : Promise<boolean>{
        try{
            const httpResult =  await this.httpService.put(API_SAVE_REVIEW_DETAILS, data);
            return httpResult.data.isSuccess;
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async loadServices(businessId : number) : Promise<BusinessLoadServicesResultPojo> {
        try{
            this.validator.isNumberGreaterThan('businessId', businessId);
            const url = `${API_BUSINESS_LOAD_SERVICES}${businessId}`
            const httpResult =  await this.httpService.get(url, {});
            return { isSuccess : httpResult.data.isSuccess, services : httpResult.data.response.services };
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async uploadDocument(data: any){
        try{
            const formData = new FormData;
            formData.append('businessId', data.businessId);
            formData.append('documentType', data.documentType);
            formData.append('fileInputFieldName', data.fileInputFieldName);
            formData.append(data.fileInputFieldName, data.document[0]);
            let api = API_BUSINESS_LICENSE_DOCUMENT;
            if(data.documentType === BUSINESS_DOCUMENT_TYPE_AGREEMENT_DOC){
                api = API_BUSINESS_AGREEMENT_DOCUMENT;
            }
            const httpResult = await this.httpService.postFormData(api, formData);
            return httpResult.data.response;
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async getLicenseDocument(businessId : number) : Promise<BusinessLicenseDocumentFetchResultPojo> {
        try {
            this.validator.isNumberGreaterThan('businessId', businessId);
            const url = `${API_BUSINESS_LICENSE_DOCUMENT}/${businessId}`;
            const httpResult = await this.httpService.get(url, {});
            return { isSuccess: httpResult.data.isSuccess, imageUrl : httpResult.data.response.imageUrl }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async deleteLicense (businessId : number) : Promise<boolean>{
        try {
            this.validator.isNumberGreaterThan('businessId', businessId);
            const httpResult = await this.httpService.delete(API_BUSINESS_LICENSE_DOCUMENT, { businessId });
            return httpResult.data.response;
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async getAgreementDocument(businessId : number) : Promise<BusinessLicenseDocumentFetchResultPojo> {
        try {
            this.validator.isNumberGreaterThan('businessId', businessId);
            const url = `${API_BUSINESS_AGREEMENT_DOCUMENT}/${businessId}`;
            const httpResult = await this.httpService.get(url, {});
            return { isSuccess: httpResult.data.isSuccess, imageUrl : httpResult.data.response.imageUrl }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async deleteAgreement(businessId : number) : Promise<boolean>{
        try {
            this.validator.isNumberGreaterThan('businessId', businessId);
            const httpResult = await this.httpService.delete(API_BUSINESS_AGREEMENT_DOCUMENT, { businessId });
            return httpResult.data.response;
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async saveBusinessSearchDetails(data: BusinessPojo) : Promise<BusinessSavePojo>{
        try{
            const httpResult = await this.httpService.post(API_SAVE_BUSINESS_SEARCH_DETAILS, data);
            return { isSuccess : httpResult.data.isSuccess, businessId : httpResult.data.response.businessId, message : httpResult.data.response };
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }

    async updateBusinessNotification(data: BusinessPojo) : Promise<BusinessSavePojo>{
        try{
            const httpResult = await this.httpService.put(API_UPDATE_BUSINESS_NOTIFICATION, data);
            return { isSuccess : httpResult.data.isSuccess, businessId : httpResult.data.response.businessId, message : httpResult.data.response };
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }

    async updateBusinessPayment(data: BusinessPojo) : Promise<BusinessSavePojo>{
        try{
            const httpResult = await this.httpService.put(API_UPDATE_BUSINESS_PAYMENT, data);
            return { isSuccess : httpResult.data.isSuccess, businessId : httpResult.data.response.businessId, message : httpResult.data.response };
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
}