import HttpService from "../../Http/HttpService";
import Logger from "../../Logger/Logger";
import Validator from "../../Validator/Validator";
import { FILE_INPUT_FIELD_NAME_EMPLOYEE_IMAGES } from "../BusinessConstant";
import {API_EMPLOYEE_IMAGE_FETCH_ALL, API_ADD_EMPLOYEE, API_DELETE_EMPLOYEE_IMAGE, API_UPLOAD_EMPLOYEE_IMAGE, API_LOAD_ALL_EMPLOYEES_FOR_BUSINESS_ID, API_LOAD_BY_ID_EMPLOYEE, API_UPDATE_EMPLOYEE, API_SAVE_EMPLOYEE_IMAGES, API_DELETE_EMPLOYEE, API_GET_EMPLOYEE_ONLY_WITH_IMAGE_FOR_BUSINESS, API_GET_ALL_LANGUAGES } from "./EmployeeConstant";
import EmployeeFetchAllResultPojo from "./EmployeePojo/EmployeeFetchAllResultPojo";
import EmployeeFetchAllImageResultPojo from "./EmployeePojo/EmployeeFetchAllImageResultPojo";
import EmployeeFetchImageResultPojo from "./EmployeePojo/EmployeeFetchImageResultPojo";
import { EmployeeImageSaveResultPojo } from "./EmployeePojo/EmployeeImageSaveResultPojo";
import EmployeeLoadByIdResultPojo from "./EmployeePojo/EmployeeLoadByIdResultPojo";
import EmployeePojo from "./EmployeePojo/EmployeePojo";
import EmployeeSaveResultPojo from "./EmployeePojo/EmployeeSaveResultPojo";
import LanguageFetchPojo from "./EmployeePojo/LanguageFetchPojo";
export default class EmployeeService {
    private httpService = new HttpService();
    private logger = new Logger();
    private validator = new Validator();
    async create(data: EmployeePojo) : Promise<EmployeeSaveResultPojo>{
        try{
            const httpResult = await this.httpService.post(API_ADD_EMPLOYEE, data);
            return { isSuccess : httpResult.data.isSuccess, employeeId : httpResult.data.response.employeeId };
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async update(data: EmployeePojo) : Promise<EmployeeSaveResultPojo>{
        try {
            const httpResult = await this.httpService.put(API_UPDATE_EMPLOYEE, data);
            return { isSuccess : httpResult.data.isSuccess, employeeId : httpResult.data.response.employeeId };
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async loadById(employeeId: number) : Promise<EmployeeLoadByIdResultPojo>{
        try{
            this.validator.isNumberGreaterThan('employeeId', employeeId);
            const url = `${API_LOAD_BY_ID_EMPLOYEE}/${employeeId}`;
            const httpResult = await this.httpService.get(url, {});
            return { isSuccess : httpResult.data.isSuccess, employee : httpResult.data.response.employee }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async loadByBusinessId(businessId: number) : Promise<EmployeeFetchAllResultPojo> {
        try{
            this.validator.isNumberGreaterThan('businessId', businessId);
            const url: string = API_LOAD_ALL_EMPLOYEES_FOR_BUSINESS_ID + '/' + businessId;
            const httpResult =  await this.httpService.get(url, {});
            return { isSuccess : httpResult.data.isSuccess, employees : httpResult.data.response.employees }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async deleteImage(employeeId: number, isPublicImage: boolean = false) : Promise<boolean>{
        try {
            this.validator.isNumberGreaterThan('employeeId', employeeId);
            const httpResult =  await this.httpService.delete(API_DELETE_EMPLOYEE_IMAGE, { employeeId,  'isPublicImage': isPublicImage });
            return httpResult.data.isSuccess;
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }

    async uploadImage(data: any){
        const formData = new FormData();
        formData.append('businessId', data.businessId);
        formData.append('documentType', data.documentType);
        formData.append('fileInputFieldName', data.fileInputFieldName);
        formData.append(data.fileInputFieldName, data.file);
        formData.append('employeeId', data.employeeId);
        return this.httpService.postFormData(API_UPLOAD_EMPLOYEE_IMAGE, formData);
    }

    async saveImages(data: any) : Promise<EmployeeImageSaveResultPojo> {
        try {
            const formData = new FormData();
            formData.append('businessId', data.businessId);
            formData.append('documentType', data.documentType);
            formData.append('fileInputFieldName', data.fileInputFieldName);
            data.images.forEach((details: any)=>{
                formData.append( FILE_INPUT_FIELD_NAME_EMPLOYEE_IMAGES+'[]', details.file);
            });
            const httpResult = await this.httpService.postFormData(API_SAVE_EMPLOYEE_IMAGES, formData);
            return { isSuccess : httpResult.data.isSuccess, images : httpResult.data.response.images };
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }

    async delete(employeeId : number) {
        try{
            this.validator.isNumberGreaterThan('employeeId', employeeId);
            const url = `${API_DELETE_EMPLOYEE}/${employeeId}`;
            const httpResult = await this.httpService.delete(url, {});
            return { isSuccess : httpResult.data.isSuccess, response : httpResult.data.response }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
    async getEmployeesOnlyWithImagesForBusiness(businessId: number) : Promise<EmployeeFetchImageResultPojo> {
        try{
            this.validator.isNumberGreaterThan('businessId', businessId);
            const url: string = API_GET_EMPLOYEE_ONLY_WITH_IMAGE_FOR_BUSINESS + '/' + businessId;
            const httpResult =  await this.httpService.get(url, {});
            return { isSuccess : httpResult.data.isSuccess, images : httpResult.data.response.images }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }

    async fetchAll(pageNumber: number, sortType: number|null) : Promise<EmployeeFetchAllImageResultPojo> {
        try{
            this.validator.isNumberGreaterThan('pageNumber', pageNumber);
            const url: string = API_EMPLOYEE_IMAGE_FETCH_ALL + '?page=' + pageNumber + '&sortType=' + sortType;
            const httpResult =  await this.httpService.get(url, {});
            return { isSuccess : httpResult.data.isSuccess, images : httpResult.data.response.images, currentPage : httpResult.data.response.currentPage, totalResults : httpResult.data.response.totalResults }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }    

    async getAllLanguage(): Promise<LanguageFetchPojo>{
        try{
            const httpResult =  await this.httpService.get(API_GET_ALL_LANGUAGES, {});  
            return { isSuccess : httpResult.data.isSuccess, message : httpResult.data.response.message, languages : httpResult.data.response }
        } catch (e){
            this.logger.error(e);
            throw e;
        }
    }
}
